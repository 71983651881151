import { useState } from "react";
import { connect } from "react-redux";

import { ProjectUrl } from "~/env";

import Input from "@bit/healthmug.admin.input";
import Button from "@bit/healthmug.admin.button";

import { useLoader } from "~/actions/custom-hooks";
import { LoginSetEmailOrPassword, LoginFocusField, LoginGetUserLogin } from "~/actions/login-actions";
import { useLocation } from "wouter";

const LoginScreen = (props) => {
	const [location, setLocation] = useLocation();
	const [loader, startLoader, endLoader] = useLoader(false);

	const logIn = () => {
		startLoader();
		props
			.Login_Get_User_Login()
			.then(() => {
				window.location.href = `${ProjectUrl}/analytics/dashboard`;
			})
			.catch(() => endLoader());
	};

	//disable the button of the login if the email and password length is small
	const showDisabledBtn = () => {
		if (props.email.trim().length >= 6 && props.password.trim().length >= 6) return false; //show the button
		return true; ///disabled the button
	};

	return (
		<>
			<section className="bg-gradient-to-r from-black vai-primary to-primary-dark  h-full flex items-center justify-center">
				<div className="w-80 mt-8 mb-8 mx-6 relative p-4 rounded">
					<div className="flex items-center">
						<span className="w-16 h-16 block overflow-hidden rounded-full">
							<img src="image/logo-color.png" className="w-16 h-16" />
						</span>
						<div className="text-2xl font-medium text-gray-light pl-5 border-l border-gray-medium ml-5">
							Login
							<div className="text-lg">Healthmug Courier</div>
						</div>
					</div>

					<div className="mb-6 mt-8">
						<Input
							onFocus={() => props.Login_Focus_Field("email")}
							value={props.email}
							onChange={(e) => props.Login_Set_Email_Or_Password(e.target.name, e.target.value)}
							name="email"
							label="Email"
							placeholder="Enter you email"
							error={props.error_list ? props.error_list.email : null}
							stacked
							small={true}
							style={{ color: "white" }}
						/>
					</div>
					<div className="mb-1" style={{ minHeight: "80px" }}>
						<Input
							onKeyPress={(e) => (e.charCode == 13 ? logIn() : null)}
							onChange={(e) => props.Login_Set_Email_Or_Password(e.target.name, e.target.value)}
							value={props.password}
							onFocus={() => props.Login_Focus_Field("password")}
							type="password"
							name="password"
							label="Password"
							placeholder="Password"
							error={props.error_list ? props.error_list.password : null}
							stacked
							small={true}
							style={{ color: "white" }}
						/>
					</div>
					<Button onClick={logIn} loader={loader} disabled={showDisabledBtn() || loader} warning block className="mt-5">
						Login
					</Button>
				</div>
			</section>
		</>
	);
};

const mapStateToProps = (state) => ({
	email: state.email,
	password: state.password,
	error_list: state.error_list
});
const mapDispatchToProps = (dispatch) => ({
	Login_Get_User_Login: () => dispatch(LoginGetUserLogin()),
	Login_Focus_Field: (field) => dispatch(LoginFocusField(field)),
	Login_Set_Email_Or_Password: (field, value) => dispatch(LoginSetEmailOrPassword(field, value))
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
